<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div
    class="edit-room-container"
    :class="{ 'hiddenn': user ===''}"
  >
    <div class="invis" />
    <div class="edit-container">
      <div class="calendar-container">
        <!-- <vue-cal
          class="vuecal--blue-theme"
          xsmall
          :time-from="10 * 60"
          :time-step="2 * 60"
          :time="false"
          :disable-views="['day']"
          active-view="month"
          events-count-on-year-view
          :events="events"
          events-on-month-view="short"
        >
          <template #events-count="{ events }">
            <span v-if="customEventsCount(events)">
              {{ customEventsCount(events) }}
            </span>
          </template>
        </vue-cal> -->
      </div>
      <div class="second-container">
        <div class="header">
          <h1 style="font-weight: bold;">
            {{ $t("VacationUpcomingEvents") }}
          </h1>

          <!-- <button
            v-b-modal.leave-request
            class="buttonSubmit"
            type="button"
          >
            Leave Request
          </button> -->
        </div>
        <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
          <div style="width: 100%; display: flex; flex-wrap: wrap; gap: 1%;">
            <h5 style="font-size: 14px; width: 20%; margin-bottom: 10px;">
              {{ $t("VacationRemainingD") }}: {{ getVacationRequests.remainingDays }}/{{ getVacationRequests.remainingDays + getVacationRequests.countVacationTypeResponseDto.vacation }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.specialLeave != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew10") }}: {{ getVacationRequests.countVacationTypeResponseDto.specialLeave }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.appointment != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew11") }}: {{ getVacationRequests.countVacationTypeResponseDto.appointment }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.accidentLeave != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew12") }}: {{ getVacationRequests.countVacationTypeResponseDto.accidentLeave }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.vacation != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("VacationVacationSpent") }}: {{ getVacationRequests.countVacationTypeResponseDto.vacation }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.education != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew13") }}: {{ getVacationRequests.countVacationTypeResponseDto.education }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.maternity != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew14") }}: {{ getVacationRequests.countVacationTypeResponseDto.maternity }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.sickLeave != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew15") }}: {{ getVacationRequests.countVacationTypeResponseDto.sickLeave }}
            </h5>
            <h5
              v-if="getVacationRequests.countVacationTypeResponseDto.deathCase != 0"
              style="font-size: 14px;width: 20%; margin-bottom: 10px;"
            >
              {{ $t("AddNew46") }}: {{ getVacationRequests.countVacationTypeResponseDto.deathCase }}
            </h5>
          </div>
          <div
            class="year"
            style="display: flex;"
          >
            <button
              class="button"
              @click="decrementYear"
            >
              <b-icon-arrow-left />
            </button>
            <h5>{{ year }}</h5>
            <button
              class="button"
              @click="incrementYear"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  {{ $t("VacationVacationT") }}
                </th>
                <th>
                  {{ $t("VacationDateFromto") }}
                </th>
                <th>
                  {{ $t("VacationDays") }}
                </th>
                <th>
                  {{ $t("Notes") }}
                </th>
                <th style="text-align: center;">
                  {{ $t("VacationCancelV") }}
                </th>
              </tr>
            </thead>
            <tbody style="border: 1px solid #EAECF0;">
              <template v-if="getActiveVacationRequests.length > 0">
                <tr
                  v-for="vacation1 in getActiveVacationRequests"
                  :key="vacation1.vacationId"
                >
                  <td
                    v-if="vacation1.vacationType === 'DeathCase'"
                  >
                    {{ $t("AddNew46") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'Appointment'"
                  >
                    {{ $t("AddNew24") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'Education'"
                  >
                    {{ $t("AddNew21") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'SpecialLeave'"
                  >
                    {{ $t("AddNew22") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'AccidentLeave'"
                  >
                    {{ $t("AddNew20") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'Vacation'"
                  >
                    {{ $t("Vacation") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'Maternity'"
                  >
                    {{ $t("AddNew23") }}
                  </td>
                  <td
                    v-if="vacation1.vacationType === 'SickLeave'"
                  >
                    {{ $t("AddNew19") }}
                  </td>
                  <td>
                    {{ dateModified2(vacation1.startVacation) }} - {{ dateModified2(vacation1.endVacation) }}
                  </td>
                  <td>
                    {{ vacation1.numberOfDays }}
                  </td>
                  <td>
                    {{ vacation1.notes }}
                  </td>
                  <td
                    v-if="vacation1.cancelled == true"
                  >
                    {{ $t("AddNew9") }}
                  </td>
                  <td
                    v-if="vacation1.cancelled == false"
                    v-b-modal.cancel-vacation-request
                    style="padding: 9px; text-align: center; cursor: pointer; display: flex; justify-content: center;"
                    @click="cancelRequest(vacation1.vacationId)"
                  >
                    <button
                      class="button"
                      style="display: flex; justify-content: center; align-items: center; font-size: 14px;"
                    >
                      {{ $t('VacationCancel') }}
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr style="border: none;">
                  <td>
                    -
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    -
                  </td>
                </tr>
                <span style="color: red; padding: 10px;">{{ $t("AddNew16") }}</span>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="second-container"
          style="margin-top:20px"
        >
          <div class="header">
            <h1 style="font-weight: bold;">
              {{ $t("AddNew10") }}
            </h1>
          </div>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    {{ $t("VacationVacationT") }}
                  </th>
                  <th>
                    {{ $t("VacationDateFromto") }}
                  </th>
                  <th>
                    {{ $t("VacationDays") }}
                  </th>
                  <th>
                    {{ $t("Notes") }}
                  </th>
                  <th>
                    {{ $t("VacationSatus") }}
                  </th>
                </tr>
              </thead>
              <tbody style="border: 1px solid #EAECF0;">
                <template v-if="getInActiveVacationRequests.length > 0">
                  <tr
                    v-for="vacation2 in getInActiveVacationRequests"
                    :key="vacation2.vacationId"
                  >
                    <td
                      v-if="vacation2.vacationType === 'DeathCase'"
                    >
                      {{ $t("AddNew46") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'Appointment'"
                    >
                      {{ $t("AddNew24") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'Education'"
                    >
                      {{ $t("AddNew21") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'SpecialLeave'"
                    >
                      {{ $t("AddNew22") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'AccidentLeave'"
                    >
                      {{ $t("AddNew20") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'Vacation'"
                    >
                      {{ $t("Vacation") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'Maternity'"
                    >
                      {{ $t("AddNew23") }}
                    </td>
                    <td
                      v-if="vacation2.vacationType === 'SickLeave'"
                    >
                      {{ $t("AddNew19") }}
                    </td>
                    <td>
                      {{ dateModified2(vacation2.startVacation) }} -  {{ dateModified2(vacation2.endVacation) }}
                    </td>
                    <td>
                      {{ vacation2.numberOfDays }}
                    </td>
                    <td>
                      {{ vacation2.notes }}
                    </td>
                    <td
                      v-if="vacation2.cancelled == false"
                      style="color: #4BB543;"
                    >
                      {{ $t("AddNew17") }}
                    </td>
                    <td
                      v-if="vacation2.cancelled == true"
                      style="color: red;"
                    >
                      {{ $t("AddNew9") }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr style="border: none;">
                    <td>
                      -
                    </td>
                    <td>
                      -
                    </td>
                    <td>
                      -
                    </td>
                    <td>
                      -
                    </td>
                    <td>
                      -
                    </td>
                  </tr>
                  <span style="color: red; padding: 10px;">{{ $t("AddNew18") }}</span>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <!-- <LeaveRequestEmployeeModal
      :user="user"
      @pushFormUser="leaveRequests"
    /> -->
    <CancelVacationModal
      :user="user"
      :vacation-id="vacationId"
      @cancel="cancelVacationRequest"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import LeaveRequestEmployeeModal from '@/components/employees/modals/LeaveRequestEmployeeModal.vue';
import CancelVacationModal from '@/components/teams/modals/CancelVacationModal.vue';
// import VueCal from 'vue-cal'
// import 'vue-cal/dist/vuecal.css'
// import FullCalendar from '@fullcalendar/vue'
// import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment-timezone'


export default {
  components: {
    // LeaveRequestEmployeeModal,
    CancelVacationModal,
    // VueCal,
    // FullCalendar,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['user'],

  data() {
    return {
      // vacation: '',
      vacationId: '',
      year: new Date().getFullYear(),
      events: [
        {
          start: '2018-11-19 10:35',
          end: '2018-11-19 11:30',
          title: 'Doctor appointment',
        },
        {
          start: '2018-11-19 18:30',
          end: '2018-11-19 19:15',
          title: 'Dentist appointment',
        },
        {
          start: '2018-11-20 18:30',
          end: '2018-11-20 20:30',
          title: 'Crossfit',
        },
        {
          start: '2018-11-21 11:00',
          end: '2018-11-21 13:00',
          title: 'Brunch with Jane',
        },
        {
          start: '2018-11-21 19:30',
          end: '2018-11-21 23:00',
          title: 'Swimming lesson',
          class: 'vacation',
        },
        {
          start: '2023-04-29 19:30',
          end: '2023-04-30 23:00',
          title: 'Swimming lesson',
          class: 'vacation',
        },
        {
          start: '2023-04-27 12:00',
          end: '2023-04-28 14:00',
          title: 'LUNCH',
          class: 'lunch',
          background: true,
        },
        {
          start: '2018-11-20 12:00',
          end: '2018-11-20 14:00',
          title: 'LUNCH',
          class: 'lunch',
          background: true,
        },
      ],
      // calendarOptions: {
      //   plugins: [dayGridPlugin],
      //   initialView: 'dayGridMonth',
      //   weekends: false,
      //   events: [
      //     { title: 'Sick Leave', start: new Date(), end: new Date() },
      //   ],
      // },
      type: null,
    };
  },
  computed: {
    ...mapGetters([
      'getVacationRequests',
      'getActiveVacationRequests',
      'getInActiveVacationRequests',
      'getPastAndFutureVacations',
    ]),
    // calendarOptions() {
    //   // Assume `getVacationRequests` is a getter that returns an object with a `vacationRequestDtos` property
    //   const vacationRequests = this.getPastAndFutureVacations;

    //   // Map vacation requests to events that can be displayed on the calendar
    //   const events = vacationRequests.map((request) => {
    //     return {
    //       title: request.vacationType,
    //       start: new Date(request.startVacation),
    //       end: new Date(request.endVacation),
    //     };
    //   });

    //   // Return calendar options with the events added
    //   return {
    //     plugins: [dayGridPlugin],
    //     initialView: 'dayGridMonth',
    //     weekends: true,
    //     events,
    //   };
    // },
  },
  watch: {
    year(value) {
      this.loadVacationRequests({
        employeeId: this.user.userId,
        year: value,
      });
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'leaveRequest',
      'loadStoresByPagination',
      'cancelVacation',
      'loadVacationRequests',
    ]),
    cancelRequest(value) {
      this.vacationId = value
    },
    cancelVacationRequest(value) {
      this.cancelVacation(value)
        .then(() => {
          const currentYear = new Date().getFullYear();
          this.loadVacationRequests({ employeeId: this.user.userId, year: currentYear });
        })
        .catch((error) => {
          console.error(error);
        });
      // this.filteredVacationRequestsFalse();
      // this.filteredVacationRequests();
    },
    dateModified2(xdate) {
      // const originalTime = moment(xdate);
      return moment(xdate).format('DD/MM/YYYY');
    },
    onCancel() {
      this.$refs.modal.hide();
    },
    onSubmit() {
      this.$refs.modal.hide();
    },
    incrementYear() {
      this.year++;
    },
    decrementYear() {
      this.year--;
    },
  },
};
</script>

<style scoped lang="scss">
.vacation {
  background-color: red;
}

.table-container table tr th{
  padding: 14px 14px;
}
.year {
width: 12%;
display: flex;
justify-content: space-around;
align-items: center;
  button{
  width: 25px !important;
  display: flex;
  border-radius: 5px !important;
  justify-content: center;
  align-items: center;
  }
    h5{
  border: 1px solid #EAECF0;
  border-radius: 6px;
  background-color: white;
  width: 40%;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  color: #FF274F;
    }
  button {
    background: #FF274F;
    border: none;
    // width: 195px;
    // height: 36px;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 8px 22px;
  }
}
h5{
  border: 1px solid #EAECF0;
  border-radius: 6px;
  background-color: white;
  width: 50%;
  height: 36px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  color: #FF274F;
  font-size: 16px;
  font-weight: 700;
    }
.edit-room-container {
  font-family: "Montserrat";
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  .invis {
    padding-top: 164px;
  }
  .edit-container {
    width: 100%;
    // display: grid;
    // grid-auto-flow: row;
    // grid-column-gap: 47px;
    // grid-row-gap: 27px;
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(1, 1fr);
    text-align: left;
    justify-content: center;
    padding-top: 36px;
    padding-bottom: 38.5px;
    border-top: 1px solid lightgray;
    .first-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: gainsboro;
      .header {
        margin-bottom: 29px;
        h1 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }
      }
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 28px;
        grid-row-gap: 39px;
      }
      .form-group {
        margin-bottom: 0px;
      }
      .form-control {
        background-color: #e8e8e8 !important;
        box-shadow: none;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      select {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #e8e8e8 !important;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -moz-appearance: none;
        border-radius: 0.375rem;
        outline: none;
        box-shadow: none;
      }
      .save-btn {
        background: #242f6e;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        padding: 8px 46.08px;
        margin-top: 35px;
      }
    }
    .second-container {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        h1 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }
      }
      .hr{
          background-color: grey;
          min-width: 201px;
          height: 1px;
          /* UI Properties */
          border: 0.5px solid #A5A5A5;
          opacity: 0.34;
        }
      .table-container {
        table {
          width: 100%;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          border-radius: 5px;

          tr {
            border-bottom: 1px solid #EAECF0;

            th {
              padding: 14px 14px;
              background: #242f6e;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              text-align: left;
              color: #ffffff;
            }
            th:first-child {
              border-radius: 0px 0px 0px 0px;
            }
            th:last-child {
              border-radius: 0px 0px 0px 0px;
            }
            td {
              padding: 14px 14px;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              text-align: left;
              color: #000000;

              button {
                padding: 6px 18px;
                border: none;
                border-radius: 5px;
                background: #5c636a;
                color: #ffffff;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-align: left;
              }
            }
          }
          tr:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .edit-room-container {
    .edit-container {
      grid-auto-flow: row;
      grid-column-gap: 88px;
      grid-row-gap: 27px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 1fr);

      .second-container {
        margin-bottom: 30px;
      }
    }
  }
  .year {
    width: 20%;
  }
}
@media only screen and (max-width: 858px) {
  .edit-room-container {
    margin-left: 0;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 620px) {
}
@media only screen and (max-width: 499px) {
  .edit-room-container {
    padding: 0 16px;
    .invis {
      padding-top: 60px;
    }
    .edit-container {
      grid-row-gap: 54px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);

      .first-container {
        form {
          grid-template-columns: none;
          grid-row-gap: 20px;
        }
      }
    }
  }
}
.hiddenn{
  display: none;
}
.calendar-container {
  // border: 2px solid #ccc;
  // border-radius: 5px;
  // margin-top: 25px;
  // overflow: hidden;
  display: none;
}

/* Style the borders of the day and time cells */
.fc-day-header, .fc-day {
  border: 1px solid #ccc;
}

/* Style the borders of the event blocks */
.fc-event-container .fc-event, .fc-event-container .fc-bgevent {
  border: 1px solid #333;
  border-radius: 3px;
  background-color: #f0f0f0;
  color: #333;
}

</style>
